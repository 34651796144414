import React, { useState, useEffect } from 'react';
import AddButton from '../../components/Shared/AddButton/AddButton';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';

import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import { Link, useNavigate } from 'react-router-dom';
import { GradesDTO } from '../../dto/GradesDTO';
import { GradesService } from '../../services/GradesService';
import Table from '../../components/Shared/Table/CategTable';
import styles from './Grades.module.css';
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';
import { SelectChangeEvent } from '@mui/material';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';

const Grades: React.FC = () => {
  const columns = [
    { title: 'Grade - EN' },
    { title: 'Grade - AR' },
    { title: 'Grade - FR' },
    { title: 'Grade - EN - Short' },
    { title: 'Grade - AR - Short' },
    { title: 'Grade - FR - Short' },
     { title: 'Created Date' },
  ];

  const navigate = useNavigate();
  const [grades, setGrades] = useState<GradesDTO[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeButton, setActiveButton] = useState(0);  
  const [loading, setLoading] = useState<boolean>(true);  
  const [searchTerm,setSearchTerm] = useState<string>("");
  const [total,setTotal]=useState(0)
  const [selectedGradeId,setSelectedGradeId]=useState<string|null>(null);
  const [mode,setMode]=useState<'archive'|'delete'>('archive')
  const [showModal,setShowModal]=useState(false);




  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const columnKeyMapping: { [key: string]: string } = {
    'Grade - EN': 'engName',
    'Grade - FR': 'frName',
    'Grade - AR': 'arName',
    'Grade - EN - Short':'shortengName',
    'Grade - AR - Short':'shortarName',
    'Grade - FR - Short':'shortfrName',
    'Total Books': 'totalBooks',
    'Created Date': 'dateCreated',
};

  const handleSort = (column: string) => {
    const backendKey = columnKeyMapping[column];
    if (!backendKey) return;
    if(loading) return;
    setLoading(true)
    if (sortColumn === backendKey) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
        setSortColumn(backendKey);
        setSortDirection('asc');
    }
};  


  const handleSearchChange = (value: string) => {
      setLoading(true)
      setCurrentPage(1)

      setSearchTerm(value); 
  };
  
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
    setLoading(true)
    setPageSize(Number(event.target.value));
  };

  useEffect(() => {
    const fetchGradesData = async () => {
      try {
    
          const archived=activeButton===0?false:true;
          const response = await GradesService.getGrades(currentPage,pageSize,searchTerm,archived,sortColumn,sortDirection);
          if (response && response.grades && Array.isArray(response.grades)) {
            setGrades(response.grades);
            setTotal(response.total)
          } else {
            console.error('Expected an array in response.data but got:', response);

          }
      } catch (error:any) {
        showErrorToast('Error fetching grades: '+error.message)
        setGrades([]);
      }finally{
        setLoading(false)
      }
    };

    fetchGradesData();
  }, [currentPage,pageSize,activeButton,searchTerm,sortColumn,sortDirection]);

  const handleEditClick = (index: number): void => {
    const grade = grades[index];
    if (grade) {
      navigate(`/grades/edit/${grade._id}`);
    }
  };

  const handleViewClick = (index: number): void => {
    const grade = grades[index];
    if (grade) {
      navigate(`/grades/view/${grade._id}`);
    }
  };
  

  const handlePageChange = (page: number) => {
    setLoading(true)
    setCurrentPage(page);
  };

  const handleActiveButtonChange=(value:number)=>{
    setLoading(true)
    setCurrentPage(1)

    setActiveButton(value)
  }
  
  const handleArchiveClick = (id: string): void => {
    setSelectedGradeId(id);
    setMode('archive'); 
    setShowModal(true);
  };
  const handleDeleteClick = (id:string): void => {
    setSelectedGradeId(id);
    setMode('delete');
    setShowModal(true);
};
  
const handleConfirmAction = async (): Promise<void> => {
  if (selectedGradeId) {
      setLoading(true)
          try {
            if (mode === 'archive') {
              const message=await GradesService.toggleArchive( selectedGradeId);
              showSuccessToast(message);
            } else if (mode === 'delete') {
              const message=await GradesService.deleteGrade( selectedGradeId);
              showSuccessToast(message);
            }
            setGrades(grades.filter(publisher => publisher._id !== selectedGradeId));
          } catch (error:any) {
            
            showErrorToast(`Error ${mode === 'archive' ? 'toggling archive' : 'deleting'} grade: `+error.message);
            
          }finally {
              setSelectedGradeId(null);
              setLoading(false)
          }
    }
  setShowModal(false);
};  
const handleCancelAction = (): void => {
  setSelectedGradeId(null);
  setShowModal(false);
};

  const label=[
    {
      label_name:"Published",
      index:0
    },
    {
      label_name:"Archived",
      index:1
    }
  ]

  const formatDate = (oldDate:string): string => {
    if(!oldDate){
        return " "
    }
    const date = new Date(oldDate);
    const formattedDate = date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    });
    return formattedDate;
}
 
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleActiveButtonChange} />
        <div className='flex '>
          <Link to="/grades/add" className={styles.link}>
            <AddButton label="ADD GRADE" />
          </Link>
          <ExportButton label="Export" exportType='grades'/>
        </div>
      </div>
      <div className={styles.content2}>
        <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
        <PaginationInfo totalItems={total} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}/>
      </div>
      <Table
      handleSort={handleSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
        columns={columns}
        data={grades.map((grade) => ({
          "_id":grade._id,
          'Grade - EN': grade.engName || '',
          'Grade - AR': grade.arName || '',
          'Grade - FR': grade.frName || '',
          'Grade - EN - Short': grade.shortengName || '',
          'Grade - AR - Short': grade.shortarName || '',
          'Grade - FR - Short': grade.shortfrName || '',
          'Created Date': formatDate(grade.dateCreated) || '',
          'Archived':grade.archived
        }))}
        onEdit={handleEditClick}
        onView={handleViewClick}
        onDelete={handleDeleteClick}
        onArchive={handleArchiveClick }
        loading={loading}
      />
      <div className={styles.content3}>
        <Pagination3
          totalPages={Math.ceil(total / pageSize)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <ArchiveModal
            setIsOpen={setShowModal}

                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode === 'archive' ? activeButton===0?"archive":'unarchive': 'Delete'} this grade?`}
                parag={`Are you sure you want to ${mode==='archive'?activeButton===0?"archive":'unarchive':'delete'} this grade?`}
                mode={mode}
                buttonText={mode==='archive' ? activeButton===0?'Yes, archive':"Yes, unarchive  " : 'Yes, delete'}
            />
    </div>
  );
};

export default Grades;
