import React, { useEffect, useState } from 'react';
import styles from './CounterShort.module.css';
import { ReactComponent as PlusIcon } from '../../../images/Books/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../../images/Books/MinusIcon.svg';


interface PagesCounterProps {
  initialCount?: number;
  counter:number;
  onChange:(value:number)=>void;
  disabled?:boolean
}

const Counter: React.FC<PagesCounterProps> = ({ initialCount = 0,counter,onChange,disabled }) => {
  const [inputValue, setInputValue] = useState<string>(counter.toString() === '0' ? '1' : counter.toString());

  useEffect(() => {
    setInputValue(counter === 0 ? '1' : counter.toString());
  }, [counter]);

  const handleBlur = () => {
    const value = parseInt(inputValue, 10);
    if (!isNaN(value) && value > 0) {
      onChange(value);
    } else {
      setInputValue(counter === 0 ? '1' : counter.toString());
    }
  };

  const handleIncrease = () => {
    if (disabled) {
      return;
    }
    onChange(counter + 1);
  };

  const handleDecrease = () => {
    if (disabled) {
      return;
    }
    if (counter > 0) {
      onChange(counter - 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setInputValue(value);
    }
  };


  return (
    <div className={styles.pagesCounter}>
      <div className={styles.counterContainer}>
        <button onClick={handleDecrease} type='button'  className={styles.button} style={disabled?{backgroundColor:'rgb(250,250,250)'}:{}}>
          <MinusIcon className={styles.icon}   />
        </button>
        <input
          type="number"
          className={styles.smallInput}
          value={inputValue}
          onChange={handleChange}
          disabled={disabled}
          onBlur={handleBlur}
        />
        <button  onClick={handleIncrease} type='button' className={styles.button} style={disabled?{backgroundColor:'rgb(250,250,250)'}:{}}>
          <PlusIcon className={styles.icon}/>
        </button>
      </div>
    </div>
  );
};

export default Counter;
