import React, { useEffect, useState } from 'react';
import AddButton from '../../components/Shared/AddButton/AddButton';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import Table from '../../components/Shared/Table/AdminAccountsTable';
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';

import { Link, useNavigate } from 'react-router-dom';
import styles from './AdminAccounts.module.css';
import { SelectChangeEvent } from '@mui/material';
import { ArchiveResponse, UserDTO, UserResponseDTO } from '../../dto/UserDTO';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { UserService } from '../../services/UserService';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';

const AdminAccounts: React.FC = () => {
    const columns = [
        { title: 'Username', key: 'fullname' },
        { title: 'Email', key: 'email' },
        { title: 'Role', key: 'role' },
        { title: 'Created Date', key: 'dateCreated', isDate: true },
    ];

     
    const navigate = useNavigate();
    const [data,setData]=useState<UserDTO[]>([])
    const [total,setTotal]=useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm,setSearchTerm] = useState<string>("");
    const [loading,setLoading]=useState(true)
    const [activeButton, setActiveButton] = useState(0);  
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    
    const handleSort = (column: string) => {
        if(loading) return;
        setLoading(true)
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };  
    const handleSearchChange = (value: string) => {
        setSearchTerm(value); 
        setCurrentPage(1)

        setLoading(true)

    };

    const [pageSize, setPageSize] = useState(10);
    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setPageSize(Number(event.target.value));
    };

    const handlePageChange = (page: number) => {
        setLoading(true)
        setCurrentPage(page);
    };

    const getRoleClass = (role: string) => {
        switch (role) {
            case 'admin':
                return styles.superAdmin;
            case 'editor':
                return styles.editor;
            case 'sales':
                return styles.sales;
            default:
                return '';
        }
    };
 
    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    const fetchUsers = async () => {
        try {
            const active=activeButton===0?true:false;
            const response: UserResponseDTO = await UserService.getAdminUsers( currentPage, pageSize, searchTerm,active,sortColumn,sortDirection);
            if (response && Array.isArray(response.users)) {
                const formattedUsers = response.users.map((user) => ({
                    ...user,
                    dateCreated: formatDate(user.dateCreated),
                    roleClass: getRoleClass(user.role),
                }));
                setData(formattedUsers);
                setTotal(response.total);
            }
        } catch (error:any) {
            showErrorToast('Error fetching the users: '+error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();        
    }, [currentPage, pageSize, searchTerm,activeButton,sortColumn,sortDirection]); 

    const [selectedUser, setSelectedUser] = useState<UserDTO | null>(null);
    const [mode, setMode] = useState<'activate' | 'delete'>('activate'); 
    const [showModal, setShowModal] = useState(false);

    const handleConfirmAction = async (): Promise<void> => {
        if (selectedUser?._id) {
            
                try {
                    if (mode === 'activate') {
                        const response:ArchiveResponse=await UserService.toggleActive( selectedUser._id);
                        showSuccessToast(response.message)
                    } else if (mode === 'delete') {
                        await UserService.deleteUser( selectedUser._id);
                        showSuccessToast('User deleted successfully');
                    }
                    setData(data.filter(user => user._id !== selectedUser._id));
                    setSelectedUser(null);
                } catch (error:any) {
                    showErrorToast(`Error ${mode === 'activate' ? 'toggling active' : 'deleting'} account: `+error.message)
                
                }
            
        }
        setShowModal(false);
    };

    const handleCancelAction = (): void => {
        setSelectedUser(null);
        setShowModal(false);
    };

    const handleArchiveClick = (user: UserDTO): void => {
        setSelectedUser(user);
        setMode('activate'); 
        setShowModal(true);
    };

    const handleDeleteClick = (user:UserDTO): void => {
        setSelectedUser(user);
        setMode('delete');
        setShowModal(true);
    };
    const label =[
        {
            label_name: "Active",
            index: 0
        },
        {
            label_name: "Deactivated",
            index: 1
        }
    ]
    const handleActiveButton = (value:number)=>{
        setLoading(true)
        setCurrentPage(1)
        setActiveButton(value)
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleActiveButton} />
                <div style={{display:"flex"}}>
                    <Link to="/adminaccounts/add" className={styles.link}>
                        <AddButton label="ADD ACCOUNT" />
                    </Link>
                    <ExportButton label="Export" exportType='users'/>                 
                </div>
            </div>
            <div className={styles.content2}>
                <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
                <PaginationInfo totalItems={total} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}/>
            </div>
            <Table
                columns={columns}
                data={data}
                onEdit={(id:string) => {
                    navigate(`/adminaccounts/edit/${id}`);
                }}
                onDelete={handleDeleteClick}
                onArchive={handleArchiveClick}
                loading={loading}
                handleSort={handleSort}
            />
            <div className={styles.content3}>
                <Pagination3 totalPages={Math.ceil(total / pageSize)} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
            
            <ArchiveModal
            setIsOpen={setShowModal}
                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode === 'activate' ? selectedUser?.active? 'Deactivate':"Activate" : 'Delete'} this user?`}
                parag={`Sure you want to ${mode === 'activate' ?  selectedUser?.active? 'Deactivate':"Activate" : 'Delete'} this user?`}
                mode={mode} 
                buttonText={mode==='activate' ? selectedUser?.active?'Yes, Deactivate':'Yes, Activate': 'Yes, delete'}

            />
        </div>
    );
}

export default AdminAccounts;
