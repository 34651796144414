// GradesForm.tsx
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ShortTextField from '../../components/Shared/Textfields/ShortTextfield';
import { GradesService } from '../../services/GradesService';
import styles from './GradesForm.module.css';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { GradesDTO } from '../../dto/GradesDTO';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../UserContext';

// Define a type for form data
interface FormGradeData {
  engName: string;
  frName: string;
  arName: string;
  shortengName: string;
  shortfrName: string;
  shortarName: string;
  archived:boolean;
}

interface GradesFormProps {
  mode: 'add' | 'edit' | 'view';
}

const GradesForm: React.FC<GradesFormProps> = ({ mode }) => {
  const {user}=useContext(UserContext)
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [gradeData, setGradeData] = useState<FormGradeData>({
    engName: '',
    frName: '',
    arName: '',
    shortengName: '',
    shortfrName: '',
    shortarName: '',
    archived:false
  });

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<'archive' | 'delete'>('archive');

  const [loading,setLoading]=useState(true)
  const [buttonLoading,setButtonLoading]=useState(false);

  useEffect(() => {
    const fetchGrade = async () => {
      if (!id) return;
      try {
        
        
        const fetchedData:GradesDTO = await GradesService.getGradeById(id);
        if (fetchedData) {
          setGradeData({
            engName: fetchedData.engName || '',
            frName: fetchedData.frName || '',
            arName: fetchedData.arName || '',
            shortengName: fetchedData.shortengName || '',
            shortfrName: fetchedData.shortfrName || '',
            shortarName: fetchedData.shortarName || '',
            archived:fetchedData.archived
          });
        }

      } catch (error:any) {
        showErrorToast('Error fetching grade: '+error.message)
        
      }finally{
        setLoading(false)
      }
    };

    if ((mode === 'view' || mode === 'edit') && id) {
      fetchGrade();
    }else{
      setLoading(false)
    }
  }, [mode, id]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!validateForm()){
      return
    }
    setButtonLoading(true)
    try {
      
      if (mode === 'add') {
        await GradesService.createGrade( gradeData);
        showSuccessToast('Grade created successfully!')
      } else if (mode === 'edit' && id) {
        await GradesService.updateGrade( id, gradeData);
        showSuccessToast('Grade updated successfully!')
      }
      navigate('/grades');  
    } catch (error:any) {
      showErrorToast(`Error ${mode==='add'?'creating':'updating'} grade: `+error.message)
    }finally{
      setButtonLoading(false)
    }
  };
 

  const validateForm = (): boolean => {
    const missingFields: string[] = [];

    // Check for missing or invalid fields
    if (!gradeData.engName.trim()) missingFields.push('English Name');
    if (!gradeData.frName.trim()) missingFields.push('French Name');
    if (!gradeData.arName.trim()) missingFields.push('Arabic Name');
    if (!gradeData.shortengName.trim()) missingFields.push('Short English Name');
    if (!gradeData.shortfrName.trim()) missingFields.push('Short French Name');
    if (!gradeData.shortarName.trim()) missingFields.push('Short Arabic Name');

    // If any fields are missing, log them and return false
    if (missingFields.length > 0) {
        showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
        return false;
    }

    return true;
};


  const handleDelete = () => {
    setModalMode('delete');
    setModalOpen(true);
  };

  const handleArchive = () => {
    setModalMode('archive');
    setModalOpen(true);
  };

  const handleConfirm = async () => {
 
    if ( !id) {
      showErrorToast('Error grade id not found')
      return;
    }
    
    try {
      if (modalMode === 'delete') {
        await GradesService.deleteGrade(id);
        showSuccessToast('Grade deleted successfully');
        navigate('/grades');
      } else if (modalMode === 'archive') {
        await GradesService.toggleArchive(id);
        showSuccessToast(gradeData.archived ? 'Grade unarchived successfully' : 'Grade archived successfully');
        navigate('/grades');
      }
    } catch (error:any) {
      showErrorToast(`Error ${modalMode === 'delete' ? 'deleting' : 'toggling archive'} grade: `+error.message);
      
    }

    setModalOpen(false);
  };

  if(loading){
    return (
    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <CircularProgress/>
    </div>)
  }
  return (
    <div className={styles.background}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.Title}>Grade Long Form</div>

        <div className={styles.row}>
          <div className={styles.field}>
            <ShortTextField
              label="Grade - EN"
              value={gradeData.engName}
              onChange={(e) => setGradeData({ ...gradeData, engName: e.target.value })}
              disabled={mode==='view'}
            />
          </div>
          <div className={styles.field}>
            <ShortTextField
              label="Grade - FR"
              value={gradeData.frName}
              onChange={(e) => setGradeData({ ...gradeData, frName: e.target.value })}
              disabled={mode==='view'}

            />
          </div>
          <div className={styles.field}>
            <ShortTextField
              label="Grade - AR"
              value={gradeData.arName}
              onChange={(e) => setGradeData({ ...gradeData, arName: e.target.value })}
              disabled={mode==='view'}

            />
          </div>
        </div>

        <div className={styles.Title}>Grade Short Form</div>

        <div className={styles.row}>
          <div className={styles.field}>
            <ShortTextField
              label="Grade - EN - Short"
              value={gradeData.shortengName}
              onChange={(e) => setGradeData({ ...gradeData, shortengName: e.target.value })}
              disabled={mode==='view'}

            />
          </div>
          <div className={styles.field}>
            <ShortTextField
              label="Grade - FR - Short"
              value={gradeData.shortfrName}
              onChange={(e) => setGradeData({ ...gradeData, shortfrName: e.target.value })}
              disabled={mode==='view'}

            />
          </div>
          <div className={styles.field}>
            <ShortTextField
              label="Grade - AR - Short"
              value={gradeData.shortarName}
              onChange={(e) => setGradeData({ ...gradeData, shortarName: e.target.value })}
              disabled={mode==='view'}

            />
          </div>
        </div>

        {mode !== 'view' && (
          <div className={styles.buttonContainer}>
            {mode === 'edit' && user?.role==="admin"? (
              <>
                <button
                  className={styles.DeleteButton}
                  type="button"
                  onClick={handleDelete}
                >
                  DELETE
                </button>
                <button
                  className={styles.ArchiveButton}
                  type="button"
                  onClick={handleArchive}
                  >
                  {gradeData.archived===true?"UNARCHIVE":'ARCHIVE'}
                </button>
              </>
            ):<></>}
            <button className={styles.AddButton} type="submit">
              {buttonLoading?<CircularProgress style={{color:'white'}}/>:mode === 'add' ? 'ADD' : 'UPDATE'}
            </button>
          </div>
        )}
      </form>
      <ArchiveModal
            setIsOpen={setModalOpen }

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={modalMode === 'archive' ? (gradeData.archived ? 'Unarchive Grade' : 'Archive Grade') : 'Delete Grade'}
        parag={modalMode === 'archive' ? `Are you sure you want to ${gradeData.archived ? 'unarchive' : 'archive'} this grade?` : 'Are you sure you want to delete this grade?'}
        buttonText={modalMode === 'archive' ? (gradeData.archived ? 'Unarchive' : 'Archive') : 'Delete'}
        mode={modalMode}
      />
    </div>
  );
};

export default GradesForm;
