import React from 'react';
import styles from './Pagination3.module.css';
import { ReactComponent as ChevronLeftIcon } from '../../../images/Shared/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../images/Shared/chevron-right.svg';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination3: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages && page!==currentPage) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
  
    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            className={`${styles.pageButton} ${currentPage === i ? styles.active : ''}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Add "1" always
      pages.push(
        <button
          key={1}
          className={`${styles.pageButton} ${currentPage === 1 ? styles.active : ''}`}
          onClick={() => handlePageChange(1)}
        >
          1
        </button>
      );
  
      if (currentPage > 3) {
        pages.push(<span key="start-ellipsis" className={styles.ellipsis}>...</span>);
      }
  
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);
  
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            className={`${styles.pageButton} ${currentPage === i ? styles.active : ''}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
  
      if (currentPage < totalPages - 2) {
        pages.push(<span key="end-ellipsis" className={styles.ellipsis}>...</span>);
      }
  
      // Add the last page always
      pages.push(
        <button
          key={totalPages}
          className={`${styles.pageButton} ${currentPage === totalPages ? styles.active : ''}`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }
  
    return pages;
  };
  

  return (
    <div className={styles.pagination}>
      <button
        className={styles.iconButton}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon />
      </button>
      {renderPageNumbers()}
      <button
        className={styles.iconButton}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};

export default Pagination3;
