import React, { useEffect, useState } from 'react';
import DashboardCard from '../../components/Dashboard/DashboardCard';
import UserTable from '../../components/Dashboard/UserTable';
import TopBooksSection from '../../components/Dashboard/TopBooksSection';
import { ReactComponent as UsersIcon } from '../../images/Dashboard/UsersIcon.svg'; 
import { ReactComponent as LibraryIcon } from '../../images/Dashboard/NewIcon.svg'; 
import { ReactComponent as BooksIcon } from '../../images/Dashboard/BookIcon.svg'; 
import { DashboardServices } from '../../services/DashboardServices';
import { DashboardDTO } from '../../dto/DashboardDTO';
import { showErrorToast } from '../../components/Shared/Toastify/toastHelper';

const Dashboard: React.FC = () => {
  const [data,setData]=useState<DashboardDTO | null>();
  const [loading,setLoading]=useState(true)

  useEffect(()=>{
    const fetchData=async()=>{
      try {
        const data=await DashboardServices.getDashboardData()
        setData(data)
      } catch (error:any) {
        showErrorToast('Error fetching data: '+error.message)        
      }finally {
        setLoading(false)
      }
      
    }
    fetchData()
  },[])

  
  return (
    <div style={{ flex: '1'}}>
      
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
        <>
          <DashboardCard number={data?.userCount||0} total={data?.allUserCount||0} icon={<UsersIcon />} title="Registered Users Today" loading={loading}/>
          <DashboardCard number={data?.usersAddedBooks||0} total={data?.allUsersThatHaveAtleastOneBookInLibrary||0}icon={<LibraryIcon />} title="Users Added a Book to Their Libraries" loading={loading}/>
          <DashboardCard number={data?.publishedBooksCount||0} total={data?.allPublishedBooksAvailable||0} icon={<BooksIcon />} title="Published Books" loading={loading}/>
        </>
        
      </div>

      <div style={{marginTop: 35}}>
        <UserTable users={data?.recentUsers }  loading={loading}/>
      </div>

      <div style={{ marginTop: 35 }}>
        <TopBooksSection books={data?.topValidatedBooks || null} loading={loading} />
      </div>
    </div>
  );
};

export default Dashboard;
