import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './AnnouncementsForm.module.css';
import { ReactComponent as UploadIcon } from '../../images/Shared/Image.svg';

import {  AnnouncementsService } from '../../services/AnnouncementsService';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { CircularProgress } from '@mui/material';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { UserContext } from '../../UserContext';


interface PublisherFormProps {
  mode: 'add' | 'edit' | 'view';
}

interface AnnouncementsData {
  title:string;
  imageUrl:File|null;
  existingImageUrl?: string; 
  archived:boolean
}

const AnnouncementsForm: React.FC<PublisherFormProps> = ({ mode }) => {
  const {user}=useContext(UserContext )
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const imageInputRef=useRef<HTMLInputElement|null>(null)

  const [announcementsData, setAnnouncementsData] = useState<AnnouncementsData>({
    title:'',
    imageUrl:null,
    archived:false,
  });

  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const [loading,setLoading]=useState(true)
  const [buttonLoading,setButtonLoading]=useState(false);

  const [modalOpen,setModalOpen]=useState(false)
  const [modalMode,setModalMode]=useState<'archive'|'delete'>('archive')

  const handleDelete = () => {
    setModalMode('delete');
    setModalOpen(true);
  };

  const handleArchive = () => {
    setModalMode('archive');
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    
    if (!id) {
      showErrorToast("Id not found.")
      return
    };

    try {
      if (modalMode === 'delete') {
        await  AnnouncementsService.deleteAnnouncement( id);
        showSuccessToast('Announcement deleted successfully');
        navigate('/announcements');
      } else if (modalMode === 'archive') {
        await  AnnouncementsService.toggleArchive( id);
        showSuccessToast(announcementsData.archived ? 'Announcement unarchived successfully' : 'Announcement archived successfully');
        navigate('/announcements');
      }
    } catch (error:any) {
      showErrorToast(`Error: ${modalMode === 'delete' ? 'deleting' : 'toggling archive'} annoucement: `+error.message);
      
    }

    setModalOpen(false);
  };

  useEffect(() => {
    const fetchPublisher = async () => {
      try {
        if(  ! id){
          throw new Error('error while fetch announcment no id')
        }
        const response =await  AnnouncementsService.getAnnouncementsById(id);
        setAnnouncementsData({
          title: response.title,
          imageUrl: null,
          existingImageUrl: response.imageUrl,
          archived:response.archived
        });
        setImagePreview(response.imageUrl);

      } catch (error:any) {
        showErrorToast('Error fetching annoucements: '+error.message)
        
      }finally{
        setLoading(false)
      }
    };

    if (mode !== 'add' && id) {
      fetchPublisher();
    }else{
      setLoading(false  )
    }
  }, [mode, id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnnouncementsData((prevData) => ({
      ...prevData,
      title: e.target.value,
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setAnnouncementsData((prevData) => ({
        ...prevData,
        imageUrl: file,
      }));
      setImagePreview(URL.createObjectURL(file));  
    }
  };
  
  const handleImageButtonClick=()=>{

    if(imageInputRef.current){
      imageInputRef.current.click();
    }
  }

  const validateForm = (): boolean => {
    const missingFields: string[] = [];

    // Check for missing or invalid fields
    if (!announcementsData.title.trim()) missingFields.push('Title');
    if (mode === 'add' && !announcementsData.imageUrl) missingFields.push('Image URL');

    // If any fields are missing, log them and return false
    if (missingFields.length > 0) {
        showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
        return false;
    }

    return true;
};

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!validateForm()){
      return
    }
    setButtonLoading(true)
    const formData = new FormData();
    formData.append('title', announcementsData.title);
    if (announcementsData.imageUrl) {
      formData.append('image', announcementsData.imageUrl);
    }

    try {


      if (mode === 'add') {
        await  AnnouncementsService.createAnnouncement(formData);
        showSuccessToast('Annoucement created successfully')
        navigate('/announcements');
      } else if (mode === 'edit' && id) {
        await  AnnouncementsService.updateAnnouncement(formData,id);
        showSuccessToast('Annoucement updated successfully')
        navigate(`/announcements/view/${id}`);
      }
    } catch (error:any) {
      if (mode === 'add') {
          showErrorToast('Error creating announcement: '+error.message)
      } else if (mode === 'edit' && id) {
        showErrorToast('Error updating announcement: '+error.message)

      }      
    }finally{
      setButtonLoading(false)
    }
  };
 
  if(loading){
    return (
      <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <CircularProgress/>
      </div>
    )
  }
  return (
    <div className={styles.background}>
      <div className={styles.Title}>Announcement Information</div>
      <form onSubmit={handleSubmit} className={styles.form}>
        
         <section className={styles.row}>  
          <div className={styles.field}>   
            <label className={styles.label}>Title</label>
            <textarea  value={announcementsData.title} onChange={handleInputChange} className={styles.input} disabled={mode==="view"}/>
          </div>

          <div className={styles.field}>
            <label className={styles.label}>CoverImage</label>
            <div className={styles.imageInputContainer}>
              {imagePreview && (
                  <img src={imagePreview} alt="Preview" className={styles.previewImage} />
              )}


               <input
                  type="file"
                  ref={imageInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleImageChange}

                />
                {!imagePreview && 
                  <>
                    <div>
                      <UploadIcon/>
                    </div>
                    <p>upload or drop image</p> 
                  </>
                }
                {mode!=='view'?<button className={styles.addImageButton} onClick={handleImageButtonClick} type='button'>
                  Upload
                </button>:null}
            </div>
          </div>
        </section>
        {mode !== 'view' && (
          <div className={styles.buttonContainer}>
            {mode==='edit' && user?.role==='admin'?
              <>
                <button className={styles.DeleteButton} type="button" onClick={handleDelete}>DELETE</button>
                <button className={styles.ArchiveButton} type="button" onClick={handleArchive}>{announcementsData.archived===false?'ARCHIVE':"UNARCHIVE"}</button>
              </>
              :<></>
            }
            <button className={styles.AddButton} type="submit">{buttonLoading?<CircularProgress style={{color:'white'}}/>:mode === 'add' ? 'ADD' : 'UPDATE'}</button>
          </div>
        )}
      </form>
      <ArchiveModal
            setIsOpen={setModalOpen}

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={`Sure you want to ${modalMode==='archive' ? announcementsData.archived===false?'archive':"unarchive" : 'delete'} this annoucement?`}
        parag={`Are you sure you want to ${modalMode==='archive'?announcementsData.archived===false?"archive":'unarchive':'delete'} this annoucement?`}
        buttonText={modalMode === 'archive' ? (announcementsData.archived===false ? 'Archive':'Unarchive'  ) : 'Delete'}
        mode={modalMode}
      />
    </div>
  );
}

export default AnnouncementsForm;
