import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class ContactUsService {
    public static getMessages = async (page: number, limit: number, name: string,sortColumn?:string|null,sortDirection?:string): Promise<any> => {
        try {
            const params = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                ...(name && { search:name }),
            });
            if(sortColumn&&sortDirection){
                params.append("sort",`${sortColumn}:${sortDirection}`)
            }
            const resp = await FetchAuth(`${endpoint}/contactus/getContactUs?${params.toString()}`, {
                method: "GET",
                headers: {
                    'Content-Type': "application/json",
                },
            });
 

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    }
    public static deleteMessage = async ( id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/contactus/${id}/deleteContactUs`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
             });

           
            return resp;
        } catch (error) {
             throw error;
        }
    };
    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const response = await FetchAuth(`${endpoint}/admin/exportContactUs?format=${format}`, {
                method: "GET",
            });

 
            return await response.blob();
        } catch (error) {
            throw error;
        }
    }

}
