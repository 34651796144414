import React, { useState, useEffect } from 'react';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';

import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import Table from '../../components/Shared/Table/CategTable';
import styles from './ContactUs.module.css';
import { SelectChangeEvent } from '@mui/material';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { contactUsDTo } from '../../dto/ContactUsDTO';
import { ContactUsService } from '../../services/ContactUsService';
import Modal, { Styles } from 'react-modal';
import { Property } from 'csstype';

Modal.setAppElement('#root');

const customStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%' ,
    flexDirection: 'column' as Property.FlexDirection,
    maxHeight:'70vh',
    zIndex:1000,
  },
  overlay: {
    zIndex: 1000,
  }
};


const ContactUs: React.FC = () => {
  const columns = [
    { title: 'ID' },
    { title: 'Name' },
    { title: 'Email Address' },
    { title: 'Message' },
    { title: 'Created Date' },
  ];

  const [message, setMessage] = useState<contactUsDTo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);  
  const [searchTerm,setSearchTerm] = useState<string>("");
  const [total,setTotal]=useState(0)
  const [selectedMessageId,setSelectedMessageId]=useState<string|null>(null);
  const [mode,setMode]=useState<'delete'>('delete')
  const [showModal,setShowModal]=useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [viewIndex,setViewIndex]=useState<number>(0);
const [sortColumn, setSortColumn] = useState<string | null>(null);
const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
const columnKeyMapping: { [key: string]: string } = {
  'ID': '_id',
  'Name': 'fullname',
  'Email Address': 'email',
  'Message': 'message',
  'Created Date': 'createdAt',
};

  const handleSort = (column: string) => {
    const backendKey = columnKeyMapping[column];
    if (!backendKey) return;
    if(loading) return;
    setLoading(true)
    if (sortColumn === backendKey) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
        setSortColumn(backendKey);
        setSortDirection('asc');
    }
};  

  const handleSearchChange = (value: string) => {
      setLoading(true)
      setCurrentPage(1)

      setSearchTerm(value); 
  };
  
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
    setLoading(true)
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetMessages = async () => {
      try {
          const response = await ContactUsService.getMessages(currentPage,pageSize,searchTerm,sortColumn,sortDirection);
          
          if (response && response.messages && Array.isArray(response.messages)) {
            setMessage(response.messages);
            setTotal(response.total)
          } else {
            console.error('Expected an array in response.data but got:', response);

          }
      } catch (error:any) {
        showErrorToast('Error fetching messages: '+error.message)
        
        setMessage([]);
      }finally{
        setLoading(false)
      }
    };

    fetMessages();
  }, [currentPage,pageSize,searchTerm,sortColumn,sortDirection]);




  const handlePageChange = (page: number) => {
    setLoading(true)
    setCurrentPage(page);
  };

  

  const handleDeleteClick = (id:string): void => {
    setSelectedMessageId(id);
    setMode('delete');
    setShowModal(true);
};
  
const handleConfirmAction = async (): Promise<void> => {
  if (selectedMessageId) {
      setLoading(true)
          try {
            if (mode === 'delete') {
              await ContactUsService.deleteMessage(selectedMessageId);
              showSuccessToast("Message deleted successfully.");
            }
            setMessage(message.filter(publisher => publisher._id !== selectedMessageId));
          } catch (error:any) {
            
            showErrorToast(`Error deleting message: `+error.message);
            
          }finally {
              setSelectedMessageId(null);
              setLoading(false)
          }
    }
  setShowModal(false);
};  
const handleCancelAction = (): void => {
  setSelectedMessageId(null);
  setShowModal(false);
};


  const formatDate = (oldDate:string): string => {
    if(!oldDate){
        return " "
    }
    const date = new Date(oldDate);
    const formattedDate = date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    });
    return formattedDate;
}

const onView=( index:number)=>{
  setViewIndex(index);
  toggleModal();
}
const toggleModal=()=>{
  setIsOpen((prev)=>!prev);
}



  return (
    <div className={styles.container}>
      <div className={styles.content2}>
        <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
        <div style={{display:'flex',}}>
          <PaginationInfo totalItems={total} pageSize={pageSize}
                  handlePageSizeChange={handlePageSizeChange}/>
            <ExportButton label="Export" exportType='message'/>
          </div>

      </div>
      <Table
        handleSort={handleSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        columns={columns}
        data={message.map((grade) => ({
          "_id":grade._id,
          "ID":grade._id,
          'Name': grade.fullname || '',
          'Email Address': grade.email || '',
          'Message': grade.message || '',
          'Created Date': formatDate(grade.createdAt) || '',
        }))}
        onDelete={handleDeleteClick}
        loading={loading}
        onView={onView}
      />
      <div className={styles.content3}>
        <Pagination3
          totalPages={Math.ceil(total / pageSize)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <ArchiveModal
             setIsOpen={setShowModal}

              isOpen={showModal}
              onConfirm={handleConfirmAction}
              onCancel={handleCancelAction}
              header={`Sure you want to Delete this message?`}
              parag={`Are you sure you want to delete this message?`}
              mode={mode}
              buttonText= 'Yes, delete'
            />

            <Modal
            
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                style={customStyles}
              >
                <button type="button" className={styles.closeBtn} onClick={toggleModal}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30" fill="white" className={styles.btnsvg}>
                    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
                  </svg>
                </button>
                <div className={styles.field}>
                  <label>Name</label>
                  <input
                    type="text"
                    value={message[viewIndex]?.fullname||""}
                    className={styles.textField}
                    disabled={true}
                  />
                </div>
                <div className={styles.field}>
                  <label>Email Address</label>
                  <input
                    type="text"
                    value={message[viewIndex]?.email||""}
                    className={styles.textField}
                    disabled={true}
                  />
                </div>            
                <div className={styles.field}>
                  <label>Message</label>
                  <textarea
                    value={message[viewIndex]?.message||""}
                    className={styles.textFieldLarge}
                    disabled={true}
                  />
                </div>            
          </Modal>
    </div>
    
  );
};

export default ContactUs;
