import React, { HtmlHTMLAttributes, useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import styles from './Table2.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { showSuccessToast } from '../Toastify/toastHelper';
import { UserContext } from '../../../UserContext';

interface TableColumn {
  title: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[]; 
  onEdit?: (index: number) => void;
  onView?: (index: number) => void;
  onDelete: (id:string) => void; 
  onArchive?:(id:string)=>void;
  loading:boolean;
  sortDirection: 'asc' | 'desc';
  handleSort: (column: string) => void;
  sortColumn: string | null;
 }

const Table: React.FC<TableProps> = ({ columns, data, onEdit, onView, onDelete,onArchive,sortColumn,sortDirection,loading,handleSort}) => {
  const {user}=useContext(UserContext)
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const dropdownRef =useRef<HTMLDivElement|null>(null)
  
  const selectedRowIndex = useRef<number | null>(null);
  const selectedFullText = useRef<string | undefined>(undefined);

  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  useEffect(()=>{
    const handleClickOutside=(event:MouseEvent)=>{
      if( dropdownRef.current && !dropdownRef.current.contains(event.target as Node)){
        setDropdownOpen(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return ()=>{
          document.removeEventListener('mousedown', handleClickOutside)
        }
  },[])

  const handleDelete = (id: string) => {
      onDelete(id);  
  };

  const handleRowClick = (rowIndex: number, fullText?: string) => {
    selectedRowIndex.current = rowIndex;
    selectedFullText.current = fullText;
  };

  useEffect(() => {
    const handleMouseUp = () => {
      const textSelected = window.getSelection()?.toString();
      if (textSelected && selectedFullText.current) {
        navigator.clipboard.writeText(selectedFullText.current).then(() => {
          showSuccessToast('Text copied to clipboard');
        });
      } else{
        if(selectedRowIndex.current!==null)
          onView?.(selectedRowIndex.current);
      }

      selectedRowIndex.current = null;
      selectedFullText.current = undefined;
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onView]);
  
  return ( 
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader} style={{gridTemplateColumns:`repeat(${columns.length},1fr)`}}>
          {columns.map((column, index) => (
            <div key={index} className={styles.tableHeaderCell} onClick={() => handleSort(column.title)}>
              {column.title !== 'Book Cover' && column.title !== 'Logo' && column.title !== 'Active Icon' && column.title !== 'Default Icon' && (
                <ArrowIcon
                  className={`${styles.arrowIcon} ${sortColumn === column.title ? (sortDirection === 'asc' ? styles.asc : styles.desc) : ''}`}
                />
              )}
              {column.title}
            </div>
          ))}
        </div>
        <div className={styles.tableBody}>
        {loading ? (
            <div className='text-center mt-8'>
              <CircularProgress />
            </div>
            ) : data.length > 0 && Array.isArray(data) ?   (
              data.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className={styles.tableRow} onMouseDown={(event) => {event.stopPropagation();handleRowClick(rowIndex)}} style={{gridTemplateColumns:`repeat(${columns.length},1fr)`}}>
                {columns.map((column, colIndex) => (
                  <div key={colIndex} className={styles.tableCell} onMouseDown={(event) => {event.stopPropagation();handleRowClick(rowIndex,row[column.title])}}>
                    {['Book Cover', 'Logo', 'Active Icon', 'Default Icon','Cover'].includes(column.title) ? (
                      <img src={row[column.title]} alt={column.title} className={styles.coverImage} />
                    ) : (colIndex !== columns.length - 1?(
                        <p onClick={(event)=>{event.stopPropagation();handleRowClick(rowIndex,row[column.title])}} title={row[column.title] || 'null'} className={styles.spanStyle} >
                          {row[column.title] || <i>null</i>}
                        </p>
                      ): 
                      (
                      <div style={{display:'flex', alignItems:"center",justifyContent:"space-between"}}>
                        <span onMouseDown={(event)=>{event.stopPropagation();handleRowClick(rowIndex,row[column.title])}} >
                          {row[column.title] || <i>null</i>}
                        </span>
                          <div className='relative'>
                            <div ref={dropdownRef}>
                            <DetailsIcon className={styles.detailsIcon} onMouseDown={(event) => { event.stopPropagation(); toggleDropdown(rowIndex); }} />
                            </div>
                            {dropdownOpen === rowIndex && (
                              <div className={styles.dropdownMenu}>
                              {/* Conditional rendering for admin-specific options */}
                              {user?.role === 'admin' && onArchive && (
                                <div
                                  className={styles.dropdownItem}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                    onArchive(row._id);
                                    toggleDropdown(rowIndex);
                                  }}
                                >
                                  {row.Archived!==null 
                                    ? row.Archived === true 
                                      ? "Unarchive" 
                                      : "Archive" 
                                    : row.active!==null?
                                        row.active === false 
                                        ? "Activate" 
                                        : "Deactivate":""}
                                </div>
                              )}
                            
                              {/* Edit option */}
                              {onEdit && (
                                <div
                                  className={styles.dropdownItem}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                    onEdit(rowIndex);
                                    toggleDropdown(rowIndex);
                                  }}
                                >
                                  Edit
                                </div>
                              )}
                            
                              {/* Delete option for admin */}
                              {user?.role === 'admin' && (
                                <div
                                  className={styles.dropdownItem}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                    handleDelete(row._id);
                                    toggleDropdown(rowIndex);
                                  }}
                                >
                                  Delete
                                </div>
                              )}
                            </div>
                            
                            )}  
                        </div>
                          
                      </div>
                      )
                    )}
                  </div>
                ))}
                
              </div>
              
            </React.Fragment>
          ))
            ):
              <div className='text-center mt-8'>
                <h1>No Data Found</h1>
              </div>
        }

        </div>
      </div>
    </div>
  );
};

export default Table;
