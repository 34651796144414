import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, LinearScale, CategoryScale, TooltipItem, ChartOptions } from 'chart.js';
import styles from './AccessCodeUsageSchool.module.css';
import { ReactComponent as ArrowIcon } from '../../images/Reports/ArrowDownIcon.svg';
import { ReactComponent as ArrowIconBold } from '../../images/Reports/ArrowBold.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/Reports/ArrowRightIcon.svg';
import { ReactComponent as AddIcon } from '../../images/Reports/AddIcon.svg';
import { ReactComponent as RemoveIcon } from '../../images/Reports/RemoveIcon.svg';
import { ReactComponent as SmallArrow } from '../../images/Reports/SmallIcon.svg';
import { ReactComponent as DetailsIcon } from '../../images/Reports/DetailsIcon.svg';
import { PublisherResponseDTO, PublishersDTO } from '../../dto/PublishersDTO';
import { showErrorToast } from '../Shared/Toastify/toastHelper';
import { PublishersService } from '../../services/PublishersService';
import { ReportsService } from '../../services/ReportsService';
import getYearsFrom2024 from '../../utils/dateUtils';
import { CircularProgress } from '@mui/material';

Chart.register(LineElement, PointElement, LinearScale, CategoryScale);

interface PublisherDropdown {
  name: string;
  isOpen: boolean;
  searchTerm:string;
  id:string
}

const AccessCodeUsageSchool: React.FC = () => {
  const years = useMemo<string[]>(() => getYearsFrom2024(), []);

  const [showDropdown, setShowDropdown] = useState(false);
  const [publisherDropdowns, setPublisherDropdowns] = useState<PublisherDropdown[]>([
    { name: '',
      searchTerm:'' ,
      isOpen: false,
      id:''
     },
  ]);
  const [isArrowBold, setIsArrowBold] = useState(true);
  const [yearDropdown, setYearDropdown] = useState(false);
  const [selectedYear, setSelectedYear] = useState(years[0]||'2024');
  const [allPublishers,setAllPublishers]=useState<PublishersDTO[]>([])
  const [chartData, setChartData] = useState<any>(null);

  const dropdownRef=useRef<(HTMLDivElement|null)[]>([])

  const [dropdownLoading,setDropdownLoading]=useState(true)
  const [loading,setLoading]=useState(false)
  //fetch publihsers
  useEffect(()=>{
    const fetchData =async()=>{
      try {

        setDropdownLoading(true)

        const response:PublisherResponseDTO = await PublishersService.getPublishers(false,1000000,1,'');
        if(Array.isArray(response.publishers)){
          setAllPublishers(response.publishers)
        } else {
          throw new Error('error while fetching publishers')
        }

        
      } catch (error:any) {
        showErrorToast("Error fetching publishers: "+error.message)
      }finally{
        setDropdownLoading(false)
      }
    }

    fetchData()

  },[])

  //fetch graph data
  useEffect(() => {
    const fetchGraphData = async () => {
      try {
    
        setLoading(true)
        const datasets = await Promise.all(
          publisherDropdowns
            .filter((publisher) => publisher.id)
            .map(async (publisher, index) => {
              const response = await ReportsService.getAccessCodeUsagePerPublisher(
                 publisher.id, selectedYear
              );
  
              if (!response || !Array.isArray(response.result)) {
                console.error(`Unexpected response for publisher ${publisher.name}:`, response);
                throw new Error(`Invalid data format for publisher: ${publisher.name}`);
              }
  
              // Initialize an array of 12 months with default value 0
              const monthlyData = Array(12).fill(0);
  
              // Populate monthlyData with actual values from the response
              response.result.forEach((item: { _id: { month: number }; usedCodesCount: number }) => {
                const monthIndex = item._id.month - 1; // Adjust for 0-based index
                monthlyData[monthIndex] = item.usedCodesCount;
              });
  
              return {
                label: publisher.name,
                data: monthlyData,
                borderColor: colors[index % colors.length],
                fill: false,
              };
            })
        );
  
        setChartData({
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets,
        });
      } catch (error:any) {
        showErrorToast('Error fetching graph data: '+error.message);
      }finally{
        setLoading(false)
      }
    };
  
    fetchGraphData();
  }, [selectedYear,  JSON.stringify(publisherDropdowns.filter((p) => p.id).map((p) => p.id))]);
  const colors = ['#00726B', '#BE1E2D', '#FFA500', '#800080'];
  
  const options: ChartOptions<'line'> = {
    plugins: {
      tooltip: {
        enabled: true, // Ensure tooltips are enabled
        mode: 'index', // Tooltip mode
        intersect: false, // Tooltip should show on all items at that index
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw as number;
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
    },
    interaction: {
      mode: 'nearest', // Change to nearest to ensure tooltips show on hover
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: '#e0e0e0',
        },
        ticks: {
          stepSize: 1,
          callback: (value) => Number(value).toFixed(0),
        },
      },
    },
  };
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      dropdownRef.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target as Node)) {
          setPublisherDropdowns((prev) =>
            prev.map((dropdown, i) =>
              i === index && dropdown.isOpen ? { ...dropdown, isOpen: false } : dropdown
            )
          );
        }
      });
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  


  
  

  const addPublisher = (index: number) => {
    const newPublisher:PublisherDropdown = { name: `Publisher ${publisherDropdowns.length + 1}`, isOpen: false,searchTerm:'',id:'' };
    const updatedDropdowns = [...publisherDropdowns];
    updatedDropdowns.splice(index + 1, 0, newPublisher);
    setPublisherDropdowns(updatedDropdowns);
  };

  const removePublisher = (index: number) => {
    const updatedDropdowns = publisherDropdowns.filter((_, i) => i !== index);
    setPublisherDropdowns(updatedDropdowns);
  };

  const toggleDropdown = (index: number) => {
    const updatedDropdowns = publisherDropdowns.map((publisher, i) => i === index ? { ...publisher, isOpen: !publisher.isOpen,searchTerm:'' } : publisher);
    setPublisherDropdowns(updatedDropdowns);
  };

  const selectPublisher = (publisherName: string,id:string, index: number) => {
    const updatedDropdowns = [...publisherDropdowns];
    updatedDropdowns[index].name = publisherName;
    updatedDropdowns[index].isOpen = false;
    updatedDropdowns[index].searchTerm=publisherName;
    updatedDropdowns[index].id = id
    setPublisherDropdowns(updatedDropdowns);
  };

  const handleHeaderClick = () => {
    setShowDropdown(!showDropdown);
    setIsArrowBold(!isArrowBold);
  };

  const handleYearClick = () => {
    setYearDropdown(!yearDropdown);
  };

  const selectYear = (year: string) => {
    setSelectedYear(year);
    setYearDropdown(false);
  };

  const handleSearchChange=(searchTerm:string,index:number)=> {
    const updatedDropdowns = [...publisherDropdowns];
    updatedDropdowns[index].searchTerm=searchTerm;
    setPublisherDropdowns(updatedDropdowns);
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={handleHeaderClick}>
        Access Code Usage Per Publisher
        <div className={styles.iconContainer}>
          
          {isArrowBold ? (
            <ArrowIconBold className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
          ) : (
            <ArrowRightIcon className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
          )}
        </div>
      </div>


      {showDropdown && (
        <>
        <hr className={styles.line} />
          <div className={styles.dropdownContainer}>
            {publisherDropdowns.map((publisher, index) => (
              <div key={index}>
                <div className={styles.icons}>
                  <div className={styles.dropdownTitle}>Publisher {index + 1}</div>
                  {index === publisherDropdowns.length - 1 ? (
                    <AddIcon className={styles.icon} onClick={() => addPublisher(index)} />
                  ) : null}
                  {index > 0 && index === publisherDropdowns.length - 1 ? (
                    <RemoveIcon className={styles.icon} onClick={() => removePublisher(index)} />
                  ) : null}
                </div>
                <div className={styles.dropdown} ref={(e)=>(dropdownRef.current[index]=e)}>
                  <div className={styles.dropdownHeader} onClick={() => toggleDropdown(index)}>
                    <div className={styles.inputContainer}>
                      <input 
                      type="text"
                      value={publisher.searchTerm} 
                      onChange={(e)=>handleSearchChange(e.target.value,index)} 
                      placeholder='Search publisher...'
                      className={styles.searchInput}
                      />

                    </div>
                    <ArrowIcon className={styles.icon} />
                  </div>
                  {publisher.isOpen && (
                    <div className={styles.dropdownList}>
                      {!dropdownLoading?
                        allPublishers.length!==0?
                          allPublishers
                          .filter(x=>x.fullname.toLowerCase().includes(publisher.searchTerm.toLowerCase()))
                          .map((publisher, i) => (
                            <div key={i} className={styles.dropdownItem} onClick={() => selectPublisher(publisher.fullname, publisher._id,index)}>
                              {publisher.fullname || 'No data found'}
                            </div>
                          )):<>No data found</>:
                          <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center' ,padding:'8px'}}><CircularProgress/></div>}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.chartControls}>
            <div className={styles.accessCodeLabel}>Access codes used</div>
            <div className={styles.yearDropdown}>
              <div className={styles.yearHeader} onClick={handleYearClick}>
                {selectedYear}
                <SmallArrow className={`${styles.smallArrow} ${yearDropdown ? styles.arrowUp : ''}`} />
              </div>
              {yearDropdown && (
                <div className={styles.yearList}>
                  {years.map((year, i) => (
                    <div key={i} className={styles.yearItem} onClick={() => selectYear(year)}>
                      {year}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={styles.chart}>
            {!loading?<Line data={chartData} options={{ ...options, maintainAspectRatio: false }} height={250} />:<CircularProgress/>}
          </div>

          <div className={styles.legend}>
            {publisherDropdowns.map((publisher, index) => (
              <div key={index} className={styles.legendItem}>
                <div className={styles.colorCircle} style={{ backgroundColor: colors[index % colors.length] }} />
                <span>{publisher.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AccessCodeUsageSchool;
