import { getAuth } from "firebase/auth";
import { showErrorToast } from "../components/Shared/Toastify/toastHelper";

const auth = getAuth();
 

export async function FetchAuth(url: string, options: RequestInit = {}, retry = true): Promise<any> {
    try {
        let token = await auth.currentUser?.getIdToken()
  
        options.headers = {
            ...(options.headers instanceof Headers ? Object.fromEntries(options.headers.entries()) : options.headers),
            "Authorization": `${token}`,
        } as Record<string, string>;

        const response = await fetch(url, options);

        if (!response.ok) {
            // Handle error responses
            let errorMessage = "An error occurred.";

            const errorData = await response.json().catch(()=>null); 
            if (errorData?.error) {
                errorMessage = errorData.error;
            } else if (errorData?.message) {
                errorMessage = errorData.message;
            } else if (typeof errorData === "string") {
                errorMessage = errorData;
            } else if (errorData?.error?.message) {
                errorMessage = errorData.error.message;
            }
            throw new Error(errorMessage);
        }

        return response;
    } catch (error) {
        throw error;  
    }
}

