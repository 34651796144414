import React, { useRef, useState } from 'react'
import styles from './AuthorsInput.module.css'
import { ReactComponent as XIcon } from '../../images/Shared/Xicon.svg';
interface props{
    authorsList:string[];
    setAuthors:(value:string[])=>void;
    disabled:boolean;
}
const AuthorsInput:React.FC<props>=({authorsList,setAuthors,disabled}) =>{
    const [authorName,setAuthorName]=useState<string>('')

    const nameRef=useRef<HTMLInputElement|null>(null);



    const handleInputChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        if(disabled){
            return
        }
        setAuthorName(e.target.value);
    }

    const handleRemoveAuthor=(authorName:string)=>{
        if(disabled){
            return
        }
        setAuthors(authorsList.filter((author)=>author.toLowerCase()!==authorName.toLowerCase()))
    }

    const handleEnterPress=(e:React.KeyboardEvent<HTMLInputElement>)=>{
        if(disabled){
            return
        }
        if(e.key ==='Enter' && authorName.trim() !==''){
            e.preventDefault();
            setAuthors([...authorsList,authorName])
            setAuthorName('')
        }
    }

    const focusInput=()=>{
        if(disabled){
            return
        }
        if(nameRef.current){
            nameRef.current.focus()
        }

    }
    return (
        <div className={styles.container} onClick={focusInput} style={disabled?{backgroundColor:'rgba(250,250,250,0.8)'}:{}}>
            <div className={styles.authorsList}>
                {authorsList.map((author, index) => (
                    <span key={index} className={styles.author} onClick={()=>handleRemoveAuthor(author)}>
                        <button onClick={() => handleRemoveAuthor(author)}><XIcon/></button>
                        <div>
                            {author}
                        </div>
                    </span>
                ))}
            </div>
        <input value={authorName} onChange={handleInputChange} onKeyDown={handleEnterPress} className={styles.authorsIpnut}  ref={nameRef} disabled={disabled}/>
      </div>
    )
}

export default AuthorsInput