import React, { SetStateAction } from "react";
import { UserDTO } from "./dto/UserDTO";

interface IUserContext {
    user: UserDTO | null;
    setUser: React.Dispatch<React.SetStateAction<UserDTO | null>>;
    loggedIn:boolean;
    setLoggedIn:React.Dispatch<React.SetStateAction<boolean>>;
    rememberMe:boolean;
    setRememberMe:React.Dispatch<React.SetStateAction<boolean>>;
    logOut:()=>Promise<void>;
}

const defaultState: IUserContext = {
    user: null,
    setUser: () => {},
    loggedIn:false,
    setLoggedIn:()=>{},
    rememberMe:false,
    setRememberMe:()=>{},
    logOut:async()=>{},
};

export const UserContext = React.createContext<IUserContext>(defaultState);
