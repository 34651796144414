import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import styles from './AnnouncementsTable.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from '../../../UserContext';

interface TableColumn {
  title: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  onEdit: (index: number) => void;
  loading:boolean
  onArchive:(id:string)=>void
  onDelete:(id:string)=>void
  onView:(id:string)=>void
}

const Table: React.FC<TableProps> = ({ columns, data, onEdit,loading,onArchive,onDelete,onView }) => {
  const {user}=useContext(UserContext)
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleSort = (columnTitle: string) => {
    const isAscending = sortColumn === columnTitle && sortDirection === 'asc';
    setSortColumn(columnTitle);
    setSortDirection(isAscending ? 'desc' : 'asc');
  };

  const parseValue = (value: any) => {
    if (value === null || value === undefined) return null;

    if (typeof value === 'string') {
      if (!isNaN(Date.parse(value))) return new Date(value).getTime(); // Handle dates
      const numberMatch = value.match(/\d+/); // Handle alphanumeric strings like 'Grade10'
      if (numberMatch) return parseInt(numberMatch[0], 10);
      return value.toLowerCase(); // Default string sorting (case insensitive)
    }
    return value; // Numeric and other types
  };

  const sortData = (data: any[]) => {
    if (!sortColumn) return data;

    return data.slice().sort((a, b) => {
      const aValue = parseValue(a[sortColumn as keyof typeof a]);
      const bValue = parseValue(b[sortColumn as keyof typeof b]);

      if (aValue === null && bValue !== null) return sortDirection === 'asc' ? 1 : -1;
      if (aValue !== null && bValue === null) return sortDirection === 'asc' ? -1 : 1;
      if (aValue === null && bValue === null) return 0;

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      return 0;
    });
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const sortedData = sortData(data);
  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {columns.map((column, index) => (
            <div
              key={index}
              className={styles.tableHeaderCell}
              onClick={() => column.title !== 'Cover Image' && column.title !== 'Logo' && handleSort(column.title)}
            >
              {column.title !== 'Cover Image' && column.title !== 'Logo' && (
                <ArrowIcon className={`${styles.arrowIcon} ${sortColumn === column.title ? sortDirection : 'default'}`} />
              )}
              {column.title}
            </div>
          ))}
        </div>
        <div className={styles.tableBody}>
          {loading?
              <div className='text-center mt-5'>
                <CircularProgress/>
              </div>
            :
          sortedData?.length?
            sortedData.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <div className={styles.tableRow} onClick={(event)=>{event.stopPropagation();onView(row._id)}}>
                  {columns.map((column, colIndex) => (
                    <div key={colIndex} className={styles.tableCell}>
                     {column.title === 'Cover Image' ? (
                        <div className={styles.coverImageContainer}>
                          <img
                            src={row[column.title]}
                            alt="Cover"
                            className={styles.coverImage} 
                          />
                        </div>
                      ) : colIndex !== columns.length - 1 ? (
                        <span title={row[column.title]||'null'}>
                          {row[column.title] ? row[column.title] : <i>null</i>}
                        </span>
                      ) : (
                        <div className={`${styles.tableCell} ${styles.actions}`}>
                          {row[column.title] ? row[column.title] : <i>null</i>}
                          <div className="relative">
                            <DetailsIcon
                              className={styles.detailsIcon}
                              onClick={(e) => { e.stopPropagation();toggleDropdown(rowIndex)}}
                            />
                            {dropdownOpen === rowIndex && (
                              <div className={styles.dropdownMenu}  ref={dropdownRef}>
                               {user?.role==='admin'? <div className={styles.dropdownItem} onClick={(e)=>{e.stopPropagation();onArchive(row._id);toggleDropdown(rowIndex)}}>{row.archived?"Unarchive":"Archive"}</div>:<></>}
                                <div
                                  className={styles.dropdownItem}
                                  onClick={(e) => {e.stopPropagation();onEdit(row._id);toggleDropdown(rowIndex)}}
                                >
                                  Edit
                                </div>
                                {user?.role==='admin'?<div className={`${styles.dropdownItem} ${styles.delete}`} onClick={(e)=>{e.stopPropagation();onDelete(row._id);toggleDropdown(rowIndex)}}>
                                  Delete
                                </div>:<></>}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                
              </React.Fragment>
            ))
            :
            <p className={styles.nodata}>No data found</p>
        }
        </div>
      </div>
    </div>
  );
};

export default Table;
