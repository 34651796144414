import React, { useEffect, useRef, useState } from 'react'
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import styles from './AccessCodeList.module.css'
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import { SelectChangeEvent } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../images/Shared/ArrowIcon.svg';
import { useParams } from 'react-router-dom';
import { BatchesService } from '../../services/BatchesService';
import { AccessCodeBatchDTO } from '../../dto/BatchesDTO';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import CircularProgress from '@mui/material/CircularProgress';
import { showSuccessToast } from '../../components/Shared/Toastify/toastHelper';


const label=[
    {  
        label_name: 'All Code',
        index:0
    },
    {  
        label_name: 'In Use',
        index:1
    },
    {  
        label_name: 'Not Used',
        index:2
    }
]
interface AccessCodeResponseDTO{
    accessCodes:AccessCodeBatchDTO[]
    total:number
}
function AccessCodeList() {
    const [loading,setLoading]=useState(false);
    const [searchTerm,setSearchTerm]=useState('');
    const [total,setTotal]=useState(0);
    const [activeButton,setActiveButton] =useState(0)
    const [pageSize,setPageSize]=useState(10);
    const [currentPage,setCurrentPage]=useState(1)
    const [accessCode,setAccessCode]=useState<AccessCodeBatchDTO[]>([])
    const [sortColumn,setSortColumn]=useState<string|null>(null);
    const[sortDirection,setSortDirection]=useState<"asc"|"desc">("asc");
    const columnKeyMapping:{[key:string]:string} = {
        'Batch Title': 'batchTitle',
        'Access Code': 'code',
        'Status': 'activated',
        'Created Date': 'createdDate',
        'Expiration Date': 'expirationDate'
    };
 
   
    const handleSort = (column: string) => {
        const backendKey = columnKeyMapping[column];
        if (!backendKey) return;
        if(loading) return;
        setLoading(true)
        if (sortColumn === backendKey) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(backendKey);
            setSortDirection('asc');
        }
    };
    const handlePageChange=(value:number)=>{
        setLoading(true)
        setCurrentPage(value)
    }
    const handleToggleButtons=(index:number)=>{
        if(index===activeButton){
            return;
        }
        setLoading(true)
        setActiveButton(index)
        setCurrentPage(1);
    }
    const handleSearchChange=(value:string)=>{
        setLoading(true)
        setSearchTerm(value)
    }
    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setCurrentPage(1)
        setPageSize(Number(event.target.value));
    };
    const {id}=useParams<{id:string}>();

    useEffect(()=>{
        const fetchData=async()=>{
            try {
                setLoading(true)
                 if(!id){
                    throw new Error("error fetching id")
                }
                const active=activeButton===0?null:activeButton===1? true: false
                const response:AccessCodeResponseDTO=await BatchesService.getAccessCodeForBatch(id,currentPage,pageSize,active,searchTerm,sortColumn,sortDirection)
                setAccessCode(response.accessCodes)
                setTotal(response.total)
            } catch (error) {
                console.error('error while fetching access codes: ',error)    
            }finally{
                setLoading(false)
            }
        }
        fetchData()
    },[id,currentPage,pageSize,searchTerm,activeButton,sortColumn,sortDirection])

    const columns=['Batch Title','Book Title','Access Code','Status', 'Created Date','Expiration Date']
    const selectedRowIndex = useRef<number | null>(null);
    const selectedFullText = useRef<string | undefined>(undefined);
    const handleRowClick = (rowIndex: number, fullText?: string) => {
        selectedRowIndex.current = rowIndex;
        selectedFullText.current = fullText;
      };
    useEffect(() => {
        const handleMouseUp = () => {
          const textSelected = window.getSelection()?.toString();
          if (textSelected && selectedFullText.current) {
            navigator.clipboard.writeText(selectedFullText.current).then(() => {
              showSuccessToast('Text copied to clipboard');
            });
          }
    
          selectedRowIndex.current = null;
          selectedFullText.current = undefined;
        };
    
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
          document.removeEventListener('mouseup', handleMouseUp);
        };
      },[]);
    
      const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return "null"
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    return (
        <>                
            <div className={styles.header}>
                <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleToggleButtons} />
                <ExportButton label="Export" exportType='accessCodes' batchId={accessCode[0]?.batchId}/> 
            </div>

            <div className={styles.topPagination}>
                <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
                <PaginationInfo totalItems={total} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange} />
            </div>

            <div className={styles.table}>
                <div className={styles.tableHeader}>
                    {columns.map((column,index)=>(
                        <div key={index} className={styles.tableHeaderCell} onClick={()=>handleSort(column)}>
                                <ArrowIcon/>
                                {column}
                        </div>
                    ))}
                </div>
                <div className={styles.tableBody}>
                {loading ? (
                    <div className={styles.loading}>
                        <CircularProgress/>
                    </div>
                ) : (
                        accessCode.length > 0 ? (
                            accessCode.map((code, index) => (
                                <div key={index} className={styles.tableRow}>
                                    <div className={styles.tableCell} onMouseDown={(event)=>{event.stopPropagation();handleRowClick(index,code.batchTitle)}}>{code.batchTitle}</div>
                                    <div className={styles.tableCell} onMouseDown={(event)=>{event.stopPropagation();handleRowClick(index,code.bookTitle)}}>{code.bookTitle}</div>
                                    <div className={styles.tableCell} onMouseDown={(event)=>{event.stopPropagation();handleRowClick(index,code.code)}}>{code.code}</div>
                                    <div className={styles.tableCell}>{code.activated?'In Use':'Not Used'}</div>
                                    <div className={styles.tableCell}>{formatDate(code.createdDate)}</div>
                                    <div className={styles.tableCell}>{formatDate(code.expirationDate)}</div>
                                </div>
                            ))
                        ) : (
                            <div>No access codes available</div>
                        )
                    )}
                </div>

            </div>
          <Pagination3 totalPages={Math.ceil(total / pageSize)} currentPage={currentPage} onPageChange={handlePageChange} />

        </>
    )
}

export default AccessCodeList