import React from 'react';
import styles from './DashboardCard.module.css';
import { CircularProgress } from '@mui/material';

interface DashboardCardProps {
  number: number|null;
  icon: React.ReactNode; 
  title: string;
  loading:boolean
  total:number
}

const DashboardCard: React.FC<DashboardCardProps> = ({ number, icon, title,loading,total }) => {
  return (
    <div className={styles.card}>
     {!loading?
      <>
        <div className={styles.cardHeader}>
          <div className={styles.number}>{number || 0}/{total}</div>
          <div>{icon}</div>
        </div>
        <div className={styles.title}>{title || ''}</div>
      </>
      :
      <div style={{ width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <CircularProgress/>
      </div>
      }
    </div>
  );
};

export default DashboardCard;
