import { AnnouncementsDTO } from "../dto/AnnouncementsDTO";
import { FetchAuth } from "../utils/FetchAuthUtil";

export class AnnouncementsService {
    private static endpoint = process.env.REACT_APP_API_ENDPOINT;

    public static getAnnouncements = async (archive: boolean): Promise<any> => {
        try {
            const response = await FetchAuth(`${this.endpoint}/carousel/getCarousel/admin?archived=${archive}`, {
                method: "GET",
                headers: { 'Content-Type': "application/json" }
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static getAnnouncementsById = async (id: string): Promise<any> => {
        try {
            const response = await FetchAuth(`${this.endpoint}/carousel/getById/${id}`, {
                method: "GET",
                headers: { 'Content-Type': "application/json" }
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static createAnnouncement = async ( data: FormData): Promise<any> => {
        try {
            const response = await FetchAuth(`${this.endpoint}/carousel/create`, {
                method: "POST",
                body: data
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static updateAnnouncement = async (data: FormData, id: string): Promise<any> => {
        try {
            const response = await FetchAuth(`${this.endpoint}/carousel/update/${id}`, {
                method: "PUT",
                body: data
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static toggleArchive = async ( id: string): Promise<any> => {
        try {
            const response = await FetchAuth(`${this.endpoint}/carousel/toggleArchive/${id}`, {
                method: "PATCH",
                headers: { 'Content-Type': "application/json" }
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static deleteAnnouncement = async ( id: string): Promise<string> => {
        try {
            const response = await FetchAuth(`${this.endpoint}/carousel/delete/${id}`, {
                method: "DELETE",
                headers: { 'Content-Type': "application/json" }
                
            });

 
            const data = await response.json();
            return data.message;
        } catch (error) {
            throw error;
        }
    }
}
