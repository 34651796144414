import { CreateBatchDTO, UpdateBatchDTO } from "../dto/BatchesDTO";
import { GenerateAccessCodeDTO } from "../dto/DashboardDTO";
import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class BatchesService {

    public static getBatches = async (
        page: number,
        limit: number,
        active: boolean | null,
        archived: boolean | null,
        title: string,
        sortColumn?:string|null,
        sortDirection?:string,

    ): Promise<any> => {
        try {
            const queryParams = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                ...(active !== null && { active: String(active) }),
                ...(archived !== null && { archived: String(archived) }),
                ...(title && { title })
            });
            if(sortColumn && sortDirection){
                queryParams.append("sort",`${sortColumn}:${sortDirection}`)
            }
            const response = await FetchAuth(`${endpoint}/batch/getAll?${queryParams.toString()}`, {
                method: "GET",
                headers: { 'Content-Type': "application/json" }
            });


            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static deleteBatch = async (id: string): Promise<string> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/deleteBatch/${id}`, {
                method: "DELETE",
                headers: { 'Content-Type': "application/json" }
            });

 
            const data = await response.json();
            return data.message;
        } catch (error) {
            throw error;
        }
    }

    public static getBatchById = async ( id: string): Promise<any> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/getBatch/${id}`, {
                method: "GET",
                headers: { 'Content-Type': "application/json" }
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static addBatch = async ( newBatch: CreateBatchDTO): Promise<any> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/create`, {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify(newBatch)
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static updateBatch = async ( id: string, newBatch: UpdateBatchDTO): Promise<any> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/updateBatch/${id}`, {
                method: "PUT",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify(newBatch)
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static toggleActivate = async ( id: string): Promise<string> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/toggleActive/${id}`, {
                method: "PATCH",
                headers: { 'Content-Type': "application/json" }
            });

 
            const data = await response.json();
            return data.message;
        } catch (error) {
            throw error;
        }
    }

    public static toggleArchive = async ( id: string): Promise<string> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/toggleArchive/${id}`, {
                method: "PATCH",
                headers: { 'Content-Type': "application/json" }
            });

 
            const data = await response.json();
            return data.message;
        } catch (error) {
            throw error;
        }
    }

    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const response = await FetchAuth(`${endpoint}/admin/exportBatches?format=${format}`, {
                method: "GET",
            });

 
            return await response.blob();
        } catch (error) {
            throw error;
        }
    }

    public static getAccessCodeForBatch = async (
        id: string,
        page: number,
        limit: number,
        active: boolean | null,
        title: string,
        sortColumn?:string|null,
        sortDirection?:string,
    ): Promise<any> => {
        try {
            const queryParams = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                ...(active !== null && { active: String(active) }),
                ...(title && { title })
            });
            if(sortColumn&&sortDirection){
                queryParams.append("sort",`${sortColumn}:${sortDirection}`)
            }
            const response = await FetchAuth(`${endpoint}/batch/getAccessCodes/${id}?${queryParams.toString()}`, {
                method: "GET",
                headers: { "content-type": "application/json",  }
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static getBatchesByBookId = async ( id: string): Promise<any> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/getBatchByBook/${id}`, {
                method: "GET",
                headers: { 'Content-Type': "application/json" }
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    public static generateAccessCode = async ( id: string, body: GenerateAccessCodeDTO): Promise<any> => {
        try {
            const response = await FetchAuth(`${endpoint}/batch/${id}/accessCode`, {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify(body)
            });

 
            return await response.json();
        } catch (error) {
            throw error;
        }
    }
}
