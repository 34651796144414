import React, { useEffect, useState } from 'react';
import styles from './Counter.module.css';
import { ReactComponent as PlusIcon } from '../../images/Books/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../images/Books/MinusIcon.svg';

interface PagesCounterProps {
  initialCount?: number;
  count: number;
  setCount: (value: number) => void;
  disabled: boolean;
}

const Counter: React.FC<PagesCounterProps> = ({ initialCount = 1, count, setCount, disabled }) => {
  const [inputValue, setInputValue] = useState<string>(count === 0 ? '1' : count.toString());

  // Update input value when `count` prop changes (outside of user input)
  useEffect(() => {
    if (inputValue !== '' && inputValue !== count.toString()) {
      setInputValue(count.toString());
    }
  }, [count]);

  // Function to increase by 1 (keeping decimal places intact)
  const handleIncrease = () => {
    if (disabled) return;
    const updatedCount = parseFloat((count + 1.0).toFixed(2)); // Ensure it's a float, rounded to 2 decimals
    setCount(updatedCount); // Set count as a float value
  };

  // Function to decrease by 1 (keeping decimal places intact)
  const handleDecrease = () => {
    if (disabled) return;
    if (count > 1) { // Prevent going below 1
      const updatedCount = parseFloat((count - 1.0).toFixed(2)); // Ensure it's a float, rounded to 2 decimals
      setCount(updatedCount); // Set count as a float value
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    // Allow for empty value and validate if it's a number
    if (newValue === '' || !isNaN(parseFloat(newValue))) {
      setInputValue(newValue);
      if (newValue === '') {
        setCount(0); // Set a default count of 0 when input is cleared
      } else {
        setCount(parseFloat(newValue)); // Update state with the new number (float)
      }
    }
  };

  return (
    <div className={styles.counterContainer} style={disabled ? { backgroundColor: 'rgb(250,250,250)' } : {}}>
      <button onClick={handleDecrease} type="button" className={styles.button}>
        <MinusIcon className={styles.icon} />
      </button>

      <input
        disabled={disabled}
        type="number"
        step="any"
        className={styles.input}
        value={inputValue}
        onChange={handleChange}
        min={1} // Allow decimals
      />
      <button onClick={handleIncrease} type="button" className={styles.button}>
        <PlusIcon className={styles.icon} />
      </button>
    </div>
  );
};

export default Counter;
