import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as UploadIcon } from '../../images/Shared/Image.svg';
import styles from './CategoriesForm.module.css';
import { CategoriesService } from '../../services/CategoriesService';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { CircularProgress } from '@mui/material';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { UserContext } from '../../UserContext';

interface CategoriesFormProps {
  mode: 'add' | 'edit' | 'view';
}

interface CategoryData {
  DefaultIcon: string | undefined;
  ActiveIcon: string | undefined;
  NameEN: string;
  NameFR: string;
  NameAR: string;
  activeIconFile: File | null;
  inactiveIconFile: File | null;
  archived:boolean
}

const CategoriesForm: React.FC<CategoriesFormProps> = ({ mode }) => {
  const {user}=useContext(UserContext)
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
 
  const [loading,setLoading]=useState(true)
  const [buttonLoading,setButtonLoading]=useState(false)
  const [categoryData, setCategoryData] = useState<CategoryData>({
    DefaultIcon: undefined,
    ActiveIcon: undefined,
    NameEN: '',
    NameFR: '',
    NameAR: '',
    activeIconFile: null,
    inactiveIconFile: null,
    archived: false,
  });

  const [modalOpen,setModalOpen]=useState(false)
  const [modalMode,setModalMode]=useState<'archive'|'delete'>('archive')

  const handleDelete = () => {
    setModalMode('delete');
    setModalOpen(true);
  };

  const handleArchive = () => {
    setModalMode('archive');
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    
    if ( !id) {
      showErrorToast('Error category id not found')
      return;

    }

    try {
      if (modalMode === 'delete') {
        const message=await CategoriesService.deleteCategory(id);
        showSuccessToast(message);
        navigate('/categories');
      } else if (modalMode === 'archive') {
        const message=await CategoriesService.toggleActivate( id);
        showSuccessToast(message);
        navigate('/categories');
      }
    } catch (error:any) {
      showErrorToast(`Error ${modalMode === 'delete' ? 'deleting' : 'toggling archive'} category: `+error.message);
    }

    setModalOpen(false);
  };

  //fetch categories
  useEffect(() => {
    const fetchCategory = async () => {
      try {
         if ( id) {
          const fetchedData = await CategoriesService.getCategoryById( id);
          setCategoryData({
            DefaultIcon: fetchedData.inactiveIcon,
            ActiveIcon: fetchedData.activeIcon,
            NameEN: fetchedData.engName,
            NameFR: fetchedData.frName,
            NameAR: fetchedData.arName,
            activeIconFile: null,
            inactiveIconFile: null,
            archived:fetchedData.archived
          });
        }else{
          showErrorToast("Error category not found.")
        }
      } catch (error:any) {
        showErrorToast('Error fetching category: '+error.message)
        
      }finally{
        setLoading(false)
      }
    };

    if ((mode === 'view' || mode === 'edit') && id) {
      fetchCategory();
    }else{
      setLoading(false)
    }
  }, [mode, id]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!validateForm()){
      return
    }
    setButtonLoading(true)
      try {
        const formData = new FormData();
        formData.append('engName', categoryData.NameEN);
        formData.append('frName', categoryData.NameFR);
        formData.append('arName', categoryData.NameAR);
        if (categoryData.activeIconFile) formData.append('activeIcon', categoryData.activeIconFile);
        if (categoryData.inactiveIconFile) formData.append('inactiveIcon', categoryData.inactiveIconFile);

        if (mode === 'add') {
          await CategoriesService.createCategory(formData);
          showSuccessToast('Cateogry created successfully!')
        } else if (mode === 'edit' && id) {
          await CategoriesService.updateCategory( id, formData);
          showSuccessToast('Cateogry updated successfully!')
        }
        navigate('/categories');
      } catch (error:any) {
        showErrorToast(`Error while ${mode==='add'?'creating':'updating'} category: `+error.message)
      }finally{
        setButtonLoading(false)
      }
    
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, iconType: 'active' | 'inactive') => {
    const file = e.target.files?.[0] || null;
    setCategoryData((prevState) => ({
      ...prevState,
      ...(iconType === 'active' ? { activeIconFile: file } : { inactiveIconFile: file }),
    }));
  };

  const renderImagePreview = (file: File | null, url: string | undefined) => {
    if (file) {
      return <img src={URL.createObjectURL(file)} alt="Preview" className={styles.imagePreview} />;
    } else if (url) {
      return <img src={url} alt="Preview" className={styles.imagePreview} />;
    }
    return null;
  };

  const validateForm = (): boolean => {
    const missingFields: string[] = [];

    // Check for missing or invalid fields
    if (!categoryData.NameEN.trim()) missingFields.push('English Name');
    if (!categoryData.NameFR.trim()) missingFields.push('French Name');
    if (!categoryData.NameAR.trim()) missingFields.push('Arabic Name');

    // Check for icon files in 'add' mode
    if (mode === 'add') {
        if (!categoryData.activeIconFile) missingFields.push('Active Icon');
        if (!categoryData.inactiveIconFile) missingFields.push('Inactive Icon');
    }

    // If any fields are missing, log them and return false
    if (missingFields.length > 0) {
       showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
        return false;
    }

    return true;
};


  if(loading){
    return(
      <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',height:'100%'}}>
        <CircularProgress/>
      </div>
    )
  }

  return (
    <div className={styles.background}>
    <div className={styles.Title}>Category Information</div>
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.row}>
        <div className={styles.field}>
          <label>Name - EN</label>
          <input
            type="text"
            value={categoryData.NameEN}
            onChange={(e) => setCategoryData({ ...categoryData, NameEN: e.target.value })}
            className={styles.textField}
            disabled={mode === 'view'}
          />
        </div>
        <div className={styles.field}>
          <label>Name - FR</label>
          <input
            type="text"
            value={categoryData.NameFR}
            onChange={(e) => setCategoryData({ ...categoryData, NameFR: e.target.value })}
            className={styles.textField}
            disabled={mode === 'view'}
          />
        </div>
        <div className={styles.field}>
          <label>Name - AR</label>
          <input
            type="text"
            value={categoryData.NameAR}
            onChange={(e) => setCategoryData({ ...categoryData, NameAR: e.target.value })}
            className={styles.textField}
            disabled={mode === 'view'}
          />
        </div>
      </div>

      <div className={styles.row}>
        
        <div className={styles.field}>
          <label>Active Icon</label>
          <div className={styles.fileContainer}>
            
            {categoryData.activeIconFile || categoryData.ActiveIcon ? (
              <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'column', alignItems:'center',gap:'16px',height:'100%'}}>
                <div style={{flex:1,display:'flex',alignItems:'center',justifyContent:'center',}}>
                  {renderImagePreview(categoryData.activeIconFile, categoryData.ActiveIcon)}
                </div>
                {(mode === 'edit' || mode === 'add') && (
                  <div className={styles.updaloadButtonContainer}>
                    <button
                      type="button"
                      className={styles.uploadButton}
                      onClick={() => document.getElementById('activeIconInput')?.click()}
                    >
                      UPLOAD
                    </button>
                  </div>
                )}
              </div>
            ) : (
              
              (mode === 'edit' || mode === 'add') && (
                <>
                  <UploadIcon className={styles.uploadIcon} />
                  <div className={styles.uploadtext}>Upload or drop image</div>
                  <button
                    type="button"
                    className={styles.uploadButton}
                    onClick={() => document.getElementById('activeIconInput')?.click()}
                  >
                    UPLOAD
                  </button>
                </>
              )
            )}

            <input
              id="activeIconInput"
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, 'active')}
              className={styles.fileInput}
            />
          </div>
        </div>

        <div className={styles.field}>
          <label>Inactive Icon</label>
          <div className={styles.fileContainer}>
            {categoryData.inactiveIconFile || categoryData.DefaultIcon ? (
              <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'column', alignItems:'center',gap:'16px',height:'100%'}}>
                <div style={{flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {renderImagePreview(categoryData.inactiveIconFile, categoryData.DefaultIcon)}
                </div>
                {(mode === 'edit' || mode === 'add') && (
                  <div className={styles.updaloadButtonContainer}>
                    <button
                      type="button"
                      className={styles.uploadButton}
                      onClick={() => document.getElementById('inactiveIconInput')?.click()}
                    >
                      UPLOAD
                    </button>
                  </div>
                )}
              </div>
            ) : (
              (mode === 'edit' || mode === 'add') && (
                <>
                  <UploadIcon className={styles.uploadIcon} />
                  <div className={styles.uploadtext}>Upload or drop image</div>
                  <button
                    type="button"
                    className={styles.uploadButton}
                    onClick={() => document.getElementById('inactiveIconInput')?.click()}
                  >
                    UPLOAD
                  </button>
                </>
              )
            )}

            <input
              id="inactiveIconInput"
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, 'inactive')}
              className={styles.fileInput}
            />
          </div>
        </div>
      </div>

      {/* Submit Button */}
      {mode !== 'view' && (
        <div className={styles.buttonContainer}>
          {mode !== 'add' && user?.role==="admin"? (
            <>
              <button className={styles.DeleteButton} type="button" onClick={handleDelete}>DELETE</button>
              <button className={styles.ArchiveButton} type="button" onClick={handleArchive}>{categoryData.archived===true?"UNARCHIVE":'ARCHIVE'}</button>
            </>
          ):<></>}
          <button className={styles.AddButton} type="submit">
            {buttonLoading?<CircularProgress style={{color:'white'}}/>:mode === 'add' ? 'ADD' : 'UPDATE'}
          </button>
        </div>
      )}
    </form>
    <ArchiveModal
            setIsOpen={setModalOpen}

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={`Sure you want to ${modalMode==='archive' ? categoryData.archived===false?'archive':"unarchive" : 'delete'} this category?`}
        parag={`Are you sure you want to ${modalMode==='archive'?categoryData.archived===false?"archive":'unarchive':'delete'} this category?`}
        buttonText={modalMode === 'archive' ? (categoryData.archived===false ? 'Archive':'Unarchive'  ) : 'Delete'}
        mode={modalMode}
      />
  </div>
  );
};

export default CategoriesForm;
