import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import styles from './AdminAccountsTable.module.css';
import { useNavigate } from 'react-router-dom';
import { showSuccessToast } from '../Toastify/toastHelper';
import { CircularProgress } from '@mui/material';
import { UserDTO } from '../../../dto/UserDTO';

interface TableColumn {
  title: string;
  key: string;
  isDate?: boolean;
  isNumeric?: boolean;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  onEdit: (id: string) => void;
  loading:boolean;
  onArchive: (user: UserDTO) => void;
  onDelete: (user: UserDTO) => void;
  handleSort:(column:string)=>void;
}

const AdminAccountsTable: React.FC<TableProps> = ({ columns, data, onEdit,loading,onArchive,onDelete,handleSort }) => {
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const navigate = useNavigate();
  const dropdownRef=useRef<HTMLDivElement|null>(null)
  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const selectedRowIndex = useRef<number | null>(null);
  const selectedFullText = useRef<string | undefined>(undefined);

  const handleRowClick = (rowIndex: number, fullText?: string) => {
    selectedRowIndex.current = rowIndex;
    selectedFullText.current = fullText;
  };
  
  useEffect(() => {
    const handleMouseUp = () => {
      const textSelected = window.getSelection()?.toString();
      if (textSelected && selectedFullText.current) {
        navigator.clipboard.writeText(selectedFullText.current).then(() => {
          showSuccessToast('Text copied to clipboard');
        });
      } else{
        if(selectedRowIndex.current!==null){
          const id=data[selectedRowIndex.current]._id;
          navigate(`/adminaccounts/view/${id}`)

        }
      }

      selectedRowIndex.current = null;
      selectedFullText.current = undefined;
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [data,navigate]);
  

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader} style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}>
          {columns.map((column, index) => (
            <div key={index} className={styles.tableHeaderCell} onClick={() => handleSort(column.key)}>
              <ArrowIcon className={styles.arrowIcon} />
              {column.title}
            </div>
          ))}
        </div>
       {!loading? <div className={styles.tableBody}>
          {data.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div
                className={styles.tableRow}
                style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}
                onMouseDown={(event) =>{event.stopPropagation(); handleRowClick(rowIndex)}}
              >
                {columns.map((column, colIndex) => (
                  <div
                    key={colIndex}
                    className={styles.tableCell}
                    onMouseDown={(event) =>{event.stopPropagation(); handleRowClick(rowIndex,row[column.key])}}
                    title={row[column.key] || ""}
                  >
                    {column.key === 'role' ? (
                      <span className={`${styles.roleText} ${row.roleClass}`}>{row[column.key]}</span>
                    ) : colIndex !== columns.length - 1 ? (
                      row[column.key]
                    ) : (
                      <div className={styles.lastCol}>
                        {row[column.key]}
                        <div className={styles.actions}>
                          <DetailsIcon
                            className={styles.detailsIcon}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              toggleDropdown(rowIndex);
                            }}
                          />
                          {dropdownOpen === rowIndex && (
                            <div className={styles.dropdownMenu} ref={dropdownRef}>
                              <div className={styles.dropdownItem}onMouseDown={(e) => {
                                  e.stopPropagation();
                                  onArchive(row);
                                  toggleDropdown(rowIndex)
                                }}>{row.active?"Deactivate":"Activate"}</div>
                              <div
                                className={styles.dropdownItem}
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                  onEdit(row._id);
                                  toggleDropdown(rowIndex)
                                }}
                              >
                                Edit
                              </div>
                              <div className={`${styles.dropdownItem} ${styles.delete}`} onMouseDown={(e)=>{e.stopPropagation();onDelete(row);toggleDropdown(rowIndex)}}>Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <hr className={styles.rowSeparator} />
            </React.Fragment>
          ))}
        </div>
        :
        <div style={{display:'flex',justifyContent:"center",alignItems:'center',padding:'32px'}}>
          <CircularProgress/>
        </div>

        }
      </div>
    </div>
  );
};

export default AdminAccountsTable;
